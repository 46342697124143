// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// Reds
$accentRed: #eb1f28;
$darkRed: #82161a;

// Greys
$accentGrey: #7b7979;
$darkGrey: #393a39;
$black: #282828;
$white: #ffffff;
$lightGray: #7b7979;
$mediumGray: #5b5b5b;

// Repeat for Spelling
$accentGray: #7b7979;
$darkGrey: #393a39;
$lightGray: #7b7979;
$mediumGray: #5b5b5b;

.pull-right {
  margin-top: -18px;
}

.nav_profile .content-profile .icon-list li i {
  margin-top: 4px;
}

.breadcrumb {
  background-color: $accentGray;
}

.breadcrumb > li {
  color: $white;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 80px;
}

a {
  color: $darkRed;
}

header {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
}

.container {
  padding-bottom: 30px;
}

.navbar {
  background-color: $darkGrey;
  height: 80px;
  font-size: 18px;
}

.sidebar-ul {
  padding-left: 10px;
}

.left-side {
    top: 80px;
}

span.fa.fa-bars {
  color: $white;
  font-size: 40px;
}

#collapse {
  background-color: $darkGrey;
}

ul.nav.navbar-nav.navbar-right {
  margin-top: 16px
}

.navbar-nav li.active {
  background-color: $darkGrey;
  color: $accentRed;
}

.navbar-nav li.active a {
  background-color: $darkGrey;
  color: $accentRed;
}

.navbar-brand, .navbar-nav > li > a {
  color: $white;
}

.navbar a:hover {
  color: $white;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: $darkGrey;
  color: $accentRed;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: $darkGrey;
  color: $accentRed;
}

.btn-primary {
  color: #fff;
  background-color: $lightGray;
  border-color: $lightGray;
}

.btn-primary:hover {
  background-color: $darkGrey;
}

.btn {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.btn-red {
  color: #fff;
  background-color: $darkRed;
  border-color: $darkRed;
}

.btn-red:hover {
  color: #fff;
  background-color: $accentRed;
}

.item img {
  height: auto;
}

.ih-item .info {
  color: $white;
}

.ih-item.square.effect13.colored .info h3 {
  background: rgba($darkGrey, 0.7);
}

.ih-item img {
  height: Auto;
  width: 100%;
}

.ih-item.square.effect13.colored .info {
  background-color: rgba($darkRed, 0.7);
}

.owl-carousel {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: $darkRed;
  border-color: $darkRed;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:focus, .list-group-item.active:hover {
  color: $white;
}

.list-group-item-text {
  color: $lightGray;
}

.thumbnail {
  background-color: transparent;
  border: none;
}

.game-tile {
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;
}

.game-tile-shadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.game-tile-container {
  position: relative;
  left: 0;
  top: 0;
}

.game-tile-container img {
  position: relative;
  left: 0;
  top: 0;
}

@media only screen and (max-width : 480px) {
  img.game-tile {
    max-width: 75%;
  }
}

img.game-tile.game-tile-overlay {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
}

.group-action-tile {
  width: 70%;
  height: 70%;

  background-color: $lightGray;
  font-size: 100px;
  text-align: center;

  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;
}

.group-action-tile p {
  color: $white;
  font-size: 24px;
  text-align: center;
}

.group-thumbnail {
  width: 90%;
  height: 200px;

  background-color: $lightGray;
  font-size: 100px;
  text-align: center;

  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;

  margin-bottom: 40px;
}

.group-thumbnail p {
  color: $white;
  font-size: 18px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 24px;        /* fallback */
  max-height: 24*2px;
}

.group-thumbnail a {
  color: $darkGrey;
}

.fab-container {
	position: absolute;
	bottom: 100px;
    right: 50px;
	z-index: 99;
}

.fab {
	border:none;
	font-size:1.5em;
	color:white;
	background-color: $darkRed;
	border-radius: 25px;
	width: 50px;
	height: 50px;
	margin: auto;
	-webkit-box-shadow: 2px 3px 3px 0px rgba(41, 41, 41, .3);
	-moz-box-shadow: 2px 3px 3px 0px rgba(41, 41, 41, .3);
	box-shadow: 2px 3px 3px 0px rgba(41, 41, 41, .3);
}

.fab:hover {
	background-color: $accentRed;
}

footer {
  background-color: $mediumGray;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: $darkGrey;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.copyright {
  background-color: $darkGrey;
}

.copyright .fa {
  font-size: 34px;
  color: $white;
  margin: 20px;
}

.copyright .fa:hover {
  color: $darkRed;
}
